import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'

(<any>window).SetAxiosSettings (axios);


createApp(App)
	.use(router)
	.use(ElementPlus)
	.mount('#app')
