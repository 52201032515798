import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/CreateRequest',
    name: 'CreateRequest',
    meta: {
      title: 'Crear Solicitud',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "CreateRequest" */ '../views/CreateRequest.vue')
  },
  {
    path: '/FillInMessage/:pTicket/:pMessageID',
    name: 'FillInMessage',
    meta: {
      title: 'Elaborar Respuesta',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "FillInMessage" */ '../views/FillInMessage.vue')
  },
  {
    path: '/FillSurvey',
    name: 'FillSurvey',
    meta: {
      title: 'Diligenciar Encuesta de Satisfacción',
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "FillSurvey" */ '../views/FillSurvey.vue')
  },
  {
    path: '/BrowseMyRequests',
    name: 'BrowseMyRequests',
    meta: {
      title: 'Consulta de Solicitudes',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "BrowseMyRequests" */ '../views/BrowseMyRequests.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach ((<any>window).VueUtils.RouterBeforeEach);

export default router
